import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadState } from './LocalStorage';
import thunk from "redux-thunk";

import rootReducer from "./reducers";

const persistedState = loadState();
var initialState = persistedState != null
? persistedState
: {};

// Logger with default options
 const middleware = [thunk];
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
 );

export default store;