import {
  ON_USER_START,
   ON_USER_FAIL,
   SET_MESSAGE
} from "./types";
import { doc, getDoc,updateDoc,  onSnapshot, query, addDoc, where,  collection, orderBy} from "firebase/firestore";

import { db } from "../../utils/firebase"; 
import userService from "../services/userService"; 
import authService from "../services/authService";
import { startQm  } from "../actions/queueManagement";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import bcrypt from 'bcryptjs'

const auth = getAuth();
var dayjs = require('dayjs')

export const fetchUser = (user) => async (dispatch) => {
  
   dispatch({type: ON_USER_START })
   if(user && user.id !==null){
     userService.getUserService(user.uid).then(
       async (userData) => {

         dispatch({
          type: 'ON_USER_SUCCESS',
          payload:  userData ,
        });

        // 1. LOAD APPSTORE CONFIGURATIONS
        // 2. LOAD UTILITIES
        // startQm(userData?.organizationId)
        if(userData && userData.organizationId) {
          var utilities = { 
            organization:null,
            organizations:[],
            storage:[], 
            apps:[], 
            users:[],
             forms:[],
            layouts:[], 
             blocks:[] 
          }

          // update utilities,
          const  updateUtitity = async (key, payload) => {
             await Object.assign(utilities[key], payload);
               dispatch({
                type: 'ON_ORGANIZATION_SUCCESS',
                payload:utilities
              });
          }

          // 0. Token here - for sockets, node api services(cloud functions, api's etc...)
          // 1. getOrganization
          
          const docRef = doc(db, "organizations", userData.organizationId);
          await getDoc(docRef);
          onSnapshot(doc(db, "organizations", userData.organizationId), async (doc) => {
            if (doc) {
              var organization
              organization = doc.data()
              organization.id = doc.id
              utilities = { ...utilities, organization:organization}
              dispatch({
                type: 'ON_ORGANIZATION_SUCCESS',
                payload:utilities
              });
             } else {
              console.log("No such organization!");
            }
          });

        
          var organizationQuery = query(collection(db, "organizations"), where("active", "==", true))
            
          onSnapshot(organizationQuery, async (querySnapshot) => {
                var organizations = []
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    organizations.push(data)
                });
                // dispatch({
                //         type: 'ON_ORGANIZATIONS_LOADED',
                //         payload:organizations
                //       });
                 await updateUtitity('organizations', organizations)
              });

          // 1.1Storage
          const storageQuery = query(collection(db, "utilities/data/storage"), 
          where("organizationId", "==", userData.organizationId))
          onSnapshot(storageQuery, async  (querySnapshot) => { 
            var storage  = [] 
              querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                storage.push(data)
              }); 
              dispatch({
                type: 'ON_STORAGE_LOADED',
                payload:storage
              });
             }, (error) => { }); 

            // 1.2 load apps, very important, => 
            var appsQuery = process.env.NODE_ENV === 'development' ?
            query(collection(db, "apps"))
            : 
            query(collection(db, "apps"), where("active", "==", true))
            
            onSnapshot(appsQuery, async (querySnapshot) => {
                  var apps = []
                  querySnapshot.forEach((doc) => {
                      let data = doc.data()
                      data.id = doc.id
                      apps.push(data)
                  });
                  await updateUtitity('apps', apps)
                });
  
            // 2. checkApp services, payments, flag status - A MIDDLEWARE SERVICE
  
              
            //  branches, forms, users, departments, storage, blocks
            // 3. Start other services
            const usersQuery = query(collection(db, "users"), where("organizationId", "==", userData.organizationId));
            onSnapshot(usersQuery, async (querySnapshot) => {
                  var users = []
                  querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    users.push(data)
                });
                 await updateUtitity('users', users)
            })
      
            // 3.2 branches
            const branchesQuery = query(collection(db, "utilities/data/branches"), 
            where("organizationId", "==", userData.organizationId));
            onSnapshot(branchesQuery, async (querySnapshot) => {
              var branches  = [] 
              querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                branches.push(data)
              });
              branches.sort((a, b) => parseInt(b.created) - parseInt(a.created))
              dispatch({
                type: 'ON_BRANCHES_LOADED',
                payload:branches
              });
             }); 
          
          // DEVICES
          const devicesQuery = query(collection(db, "/utilities/data/devices"), 
            where("organizationId", "==", userData.organizationId));
              onSnapshot(devicesQuery, async (querySnapshot) => { 
                var devices  = [] 
                querySnapshot.forEach((doc) => {
                  let data = doc.data()
                  data.id = doc.id
                  devices.push(data)
                });
                devices.sort((a, b) => parseInt(b.created) - parseInt(a.created))
                dispatch({
                  type: 'ON_DEVICES_LOADED',
                  payload:devices
                });
            }, (error) => { });

          // LAYOUTS
          const layoutsQuery = query(collection(db, "/content/layouts/signage"));
            onSnapshot(layoutsQuery, async (querySnapshot) => { 
              var layouts  = [] 
              querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                layouts.push(data)
              });
              await updateUtitity('layouts', layouts)
          }, (error) => { }); 
             
          // FORMS
          const formsQuery = query(collection(db, "appData/data-collection/forms"), 
          where("organizationId", "==", userData.organizationId));
          onSnapshot(formsQuery, async (querySnapshot) => { 
                var forms  = [] 
                querySnapshot.forEach((doc) => {
                  let data = doc.data()
                  data.id = doc.id
                  forms.push(data)
                  });
                  await updateUtitity('forms', forms)
              }, (error) => { }); 
  
          // // DEPARTMENTS
          const departmentsQuery = query(collection(db, "utilities/data/departments"), 
          where("organizationId", "==", userData.organizationId));
          onSnapshot(departmentsQuery, async (querySnapshot) => { 
                var departments  = [] 
                querySnapshot.forEach((doc) => {
                   let data = doc.data()
                  data.id = doc.id
                  departments.push(data)
                  });
                   departments.sort((a, b) => parseInt(b.created) - parseInt(a.created))
                  dispatch({
                    type: 'ON_DEPARTMENTS_LOADED',
                    payload:departments
                  });
            }, (error) => { }); 
          
            //BLOCKS, e.i room, building, office, 
            var blocks  = [] 
            const blocksQuery = query(collection(db, "utilities/data/blocks"), where("organizationId", "==", userData.organizationId));
              onSnapshot(blocksQuery, async (querySnapshot) => { 
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    blocks.push(data)
                });
                await updateUtitity('blocks', blocks)
            }, (error) => { }); 
               
    
        }

         return Promise.resolve(userData);
        
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        dispatch({
          type: ON_USER_FAIL,
         });
  
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
  
        return Promise.reject();
      }
    );
  }
};

//

export const createUser = (user) => async (dispatch) => {
  if(user !==null){
    const { email, password } = user
     authService.register( email, password).then(
      async (userData) => { return Promise.resolve(userData); },
     (error) => {
       const message =
         (error.response &&
           error.response.data &&
           error.response.data.message) ||
         error.message ||
         error.toString();
 
       dispatch({
         type: ON_USER_FAIL,
        });
 
       dispatch({
         type: SET_MESSAGE,
         payload: message,
       });
 
       return Promise.reject(error);
     }
   );
 
 }
};


export const updateUser = (user) => async (dispatch) => {
  
   if(user?.id !==null){
    userService.updateUserService(user).then(
      async (userData) => {  return Promise.resolve(userData);   },
     (error) => {
       const message =
         (error.response &&
           error.response.data &&
           error.response.data.message) ||
         error.message ||
         error.toString();
 
       dispatch({
         type: ON_USER_FAIL,
        });
 
       dispatch({
         type: SET_MESSAGE,
         payload: message,
       });
 
       return Promise.reject();
     }
   );
 



//     }
 }
};




export const updateUserStatus = (user) => async (dispatch) => {
  
  // dispatch({type: ON_USER_START })
  if(user?.id !==null){
    userService.deactivateUserService(user).then(
      async (userData) => {  
        dispatch({
         type: 'ON_USER_UPDATE',
         payload:  userData ,
       }); 
        return Promise.resolve(userData); 
     },
     (error) => {
       const message =
         (error.response &&
           error.response.data &&
           error.response.data.message) ||
         error.message ||
         error.toString(); 
       dispatch({ type: ON_USER_FAIL, }); 
       dispatch({ type: SET_MESSAGE,  payload: message, });
 
       return Promise.reject();
     }
   );

 
 }
};



// FIREBASE

export const createFirestoreUser = ( payload ) =>  async (dispatch, getState) =>{
  var { 
    branchId,  email, firstname, lastname, departmentId,
    password , permissions, profileUrl,  role, userStatus
   } = payload
   var admin = parseInt(role) === 1 ? true : false 
   var hashedPassword = await bcrypt.hashSync(password, bcrypt.genSaltSync())
   var now = dayjs().format()
   var updated = dayjs(now).unix()
   var created = dayjs(now).unix()

   var { auth, organization  } = getState()
   const organizationId = organization?.organization?.id
   let createdBy = auth?.userData?.userId

   const authentication = getAuth();
   await createUserWithEmailAndPassword( authentication, email, password)
   .then( async (userCredential) => {
    // Signed up 
    var user = userCredential?.user;  
    var userPromise
      try{
          var userPromise = await addDoc(collection(db, "users"), {
          admin, userId:user?.uid, branchId, created, email, 
          firstname, lastname, organizationId, departmentId,
          password:hashedPassword, permissions, profileUrl, 
          role, updated, active:true, userStatus, createdBy
        });
   
    } catch (err) { }
    return Promise.resolve(userPromise)
   }) 
  .catch((error) => { });  
};
 


export const updateAccount =  (payload)=> async (dispatch, getState) =>{
  var { auth  } = getState() 
  // payload.admin = parseInt(payload?.role) === 1 ? true : false
  var now = dayjs().format()
  var updated = dayjs(now).unix()
  
  //  let updatedBy = auth?.userData?.userId 
  var updatedUser = { ...payload, updated}
  console.log("Payload", updatedUser);
  var userPromise
  const taskDocRef = doc(db,  "users", payload?.id) 
  try{ 
     await updateDoc(taskDocRef, { ...updatedUser }).then((res)=>{
       
     })
//     onSnapshot(doc(db,  "users", payload?.id), async (doc) => {
//       dispatch({type:'SHOW_SWEET_ALERT', payload:{type:'success', text:'User updated' }})
//         setTimeout(() => {
//           dispatch({type:'HIDE_SWEET_ALERT'})
//          }, 4000); 
//         return doc
// });
   }
  catch (err) {
    } 
}