// UTILITIES 
export const ON_UTILITY_SUBMITED        = "ON_UTILITY_SUBMITED";

// Branches
export const ON_BRANCHES_LOADED         = "ON_BRANCHES_LOADED"; 
export const ON_BRANCH_UPDATE           = "ON_BRANCH_UPDATE";
export const ON_BRANCH_KEY_UPDATE      = "ON_BRANCH_KEY_UPDATE";
 
// Departments
export const ON_DEPARTMENTS_LOADED      = "ON_DEPARTMENTS_LOADED";
export const ON_DEPARTMENT_KEY_UPDATED  = "ON_DEPARTMENT_KEY_UPDATED"; 
export const ON_DEPARTMENT_UPDATE       = "ON_DEPARTMENT_UPDATE";

// Devices
export const ON_DEVICES_LOADED          = "ON_DEVICES_LOADED";
export const ON_DEVICE_KEY_UPDATED      = "ON_DEVICE_KEY_UPDATED"; 
export const ON_DEVICE_UPDATE           = "ON_DEVICE_UPDATE"; 


// Storage
export const ON_STORAGE_LOADED          = "ON_STORAGE_LOADED";
export const ON_STORAGE__KEY_UPDATE     = "ON_STORAGE__KEY_UPDATE"; 
 
 
 