import { auth } from "../../utils/firebase";
import {
  createUserWithEmailAndPassword, updateEmail,
  signOut, signInWithEmailAndPassword,
  updatePassword, sendPasswordResetEmail, getAuth
} from "firebase/auth";


// authentication services
const register = (email, password) => { 
  const auth = getAuth();
  console.log("email password", email, password);
  return createUserWithEmailAndPassword(auth, email, password)
 }
 
const login = (email, password) => { return signInWithEmailAndPassword(auth, email, password) }
const resetUserPassword = (email) => { return sendPasswordResetEmail(auth, email) }
const updateUserEmail = (currentUser, email) => { return updateEmail(currentUser, email) }
const updateUserPassword = (currentUser, password) =>  { return updatePassword(currentUser, password) }

const logout = () => {
  // clear user token
  localStorage.removeItem("user")
  return signOut(auth);

}

export default {
  register,
  login,
  resetUserPassword,
  updateUserEmail,
  updateUserPassword,
  logout,
};




