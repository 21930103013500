
const dayjs = require('dayjs')
var now = dayjs().format()
var updated = dayjs(now).unix()
var created = dayjs(now).unix()

export default class Constants {
    static STATUS = [
        {id:'1', name:"Active", type:'success'},
        {id:'2', name:"Inactive", type:'secondary'},
        {id:'3', name:"Pending", type:'warning'},
        {id:'4', name:"Suspended", type:'danger'},
        {id:'4', name:"Archive", type:'primary'},
      ]
    static ROLES = [
      {id:'1', name: 'SUPER ADMIN', type:"secondary" },
      {id:'2', name: 'ADMIN', type:"success"},
      {id:'3', name: 'BRANCH AMNAGER', type:"primary"},
      {id:'4', name: 'SUPERVISOR', type:"warning"},
      {id:'5', name: 'AGENT/CONSULTANT', type:"warning"},

    ]
    static SERVINT_POINT_TYPES = [
      {id:'1', name: 'Counter' },
      {id:'2', name: 'Teller', },
      {id:'3', name: 'Office' },
      {id:'4', name: 'Block' },
      {id:'5', name: 'Section' },
      {id:'5', name: 'Hall' },
      {id:'6', name: 'Boardroom' },
 
    ]
    static ALPHABET = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
    static CREATED = created
    static UPDATED = updated
}
