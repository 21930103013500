import { doc, addDoc, query, where, onSnapshot, updateDoc, collection, deleteDoc} from "firebase/firestore";
import { db } from "../../utils/firebase";
import fetch from 'node-fetch';
import Strings from '../../utils/Strings';
  
const uuid = require('uuid')
const dayjs = require('dayjs')
var now = dayjs().format()
var updated = dayjs(now).unix()
var created = dayjs(now).unix()

const SERVER = "http://0.0.0.0:80";
 

  export const getTickets = (customerId) => async (dispatch) => {
         if(customerId!=="" && customerId !==null){
             const response = await fetch(`${Strings.CLOUD_API}tickets/${customerId}`, { 
          method: 'GET',  
          customerId,
          headers: {
            'Accept': 'application/json',
           }
        })
        await response.json()
        .then(response =>{
          if(response.status && response?.data){ 
            const  tickets  = response?.data
            
             dispatch({type:'ON_TICKETS_LOADED', payload: tickets && tickets?.sort((a, b) => parseInt(a.created) - parseInt(b.created))?.reverse() }) 
            }  
          })
           .catch(error =>{ 
          }) 
        } 
  };

  export const getReviews = (customerId) => async (dispatch) => {
    if(customerId!=="" && customerId !==null){
        const response = await fetch(`${Strings.CLOUD_API}reviews/${customerId}`, { 
     method: 'GET',  
     customerId,
     headers: {
       'Accept': 'application/json',
      }
   })
   await response.json()
   .then(response =>{
     if(response.status && response?.data){ 
       const  reviews  = response?.data
       console.log("====reviews====", reviews);
        dispatch({type:'ON_REVIEWS_LOADED', payload: reviews && reviews?.sort((a, b) => parseInt(a.created) - parseInt(b.created))?.reverse() }) 
       }  
     })
      .catch(error =>{ 
     }) 
   } 
};

 
  export const getServices = (organizationId) => async (dispatch) => {
    if(organizationId){

      const storageQuery = query(collection(db, "appData/qm/services"), 
     where("organizationId", "==", organizationId))
     onSnapshot(storageQuery, async  (querySnapshot) => { 
       var services  = [] 
         querySnapshot.forEach((doc) => {
           let data = doc.data()
           data.id = doc.id
           services.push(data)
         }); 
         dispatch({
           type: 'ON_SERVICES_LOADED',
           payload:services
         });
        }, (error) => { }); 
    }
 };

  
export const getBranches = (organizationId) => async (dispatch, getState) => {
  const storageQuery = query(collection(db, "utilities/data/branches"), 
  where("organizationId", "==", organizationId))
  onSnapshot(storageQuery, async  (querySnapshot) => { 
    var services  = [] 
      querySnapshot.forEach((doc) => {
        let data = doc.data()
        data.id = doc.id
        services.push(data)
      }); 
      
      dispatch({
        type: 'ON_BRANCHES_LOADED',
        payload:services
      });
     }, (error) => { }); 
  };
  

  export const ackTicket = (id) => async (dispatch) => {
    console.log(id);
            const taskDocRef = doc(db,  "appData/qm/qrCodes",  id)
             try {
             await updateDoc(taskDocRef, { 'ack':false, 'retreaved':true })
              .then((re)=>{
                console.log(re);
              }).catch((err)=>console.error(err))
           } catch (err) { 
            console.error(err) 
             return err
             }
  
  } 
 
  export const queueTicket = (payload) => async (dispatch) => {
    payload = { ...payload } 
        try{
        await addDoc(collection(db, "appData/qm/onlineQueue"),   { ...payload } )
          .then((res) => { 
                onSnapshot(
                doc(db, "appData/qm/onlineQueue", res.id), 
                { includeMetadataChanges: true }, 
                (doc) => {
                  let ticket = doc.data()
                  ticket.id = doc.id
                  dispatch({
                    type: 'ON_TICKET_QEUEUD',
                    payload:ticket
                  });
                 
                  return Promise.resolve(ticket)
                  // ...
                });
                
              })
        .catch((error) => {   console.log("error", error);  }); 
        } catch (err) {  console.log("err", err);  }
  } 

   
  export const sendReview = (payload) => async (dispatch) => {
        payload =  {id:uuid(), ...payload, created }
       console.log("====data====", payload);
          const response = await fetch(`${Strings.CLOUD_API}reviews/add`, { 
          method: 'POST',  
          body: JSON.stringify(payload),
          headers: {
            'Accept': 'application/json',
            }
        })
        await response.json()
        .then(response =>{
           if(response.status ){ 
            // const  tickets  = response?.data
            
              dispatch({type:'ON_REVIEW_ADDED'}) 
            }  
          })
            .catch(error =>{ 
          })  
  }

 


// SERVING POINTS
export const getServingPoints = (organizationId) => async (dispatch) => {
  const storageQuery = query(collection(db, "appData/qm/serving-points"), 
  where("organizationId", "==", organizationId))
  onSnapshot(storageQuery, async  (querySnapshot) => { 
    var servingPoints  = [] 
      querySnapshot.forEach((doc) => {
        let data = doc.data()
        data.id = doc.id
        servingPoints.push(data)
      }); 
      dispatch({
        type: 'ON_SERVING_POINT__LOADED',
        payload:servingPoints
      });
    }, (error) => { }); 
};

export const addSevingPoint = (payload) => async (dispatch, getState) => {
  var {  auth, organization  } = getState()
  const organizationId = organization?.organization?.id
  let createdBy = auth?.userData?.userId
      payload = { ...payload, organizationId , createdBy, created} 
           try{
            await addDoc(collection(db, "appData/qm/serving-points"),   { ...payload } )
              .then((res) => {  return  })
            .catch((error) => {   console.log("error", error);  }); 
            } catch (err) {  console.log("err", err);  }
        
  };

export const updateSevingPoint = (payload) => async (dispatch, getState) => {
  var {  auth  } = getState()
  let createdBy = auth?.userData?.userId
  payload.updatedBy = createdBy
  payload.updated = updated
   dispatch({ type: 'ON_QM_SUBMITING' });
  const taskDocRef = doc(db,  "appData/qm/services", payload?.id)
      try {
      await updateDoc(taskDocRef, { ...payload, updated })
      onSnapshot(doc(db,  "appData/qm/services", payload?.id), async (doc) => {
          return doc
      });
    } catch (err) {   return err }
};


export const deleteServingPoint = (id) => async (dispatch, getState) => { 
      await deleteDoc(doc(db, "appData/qm/serving-points", id))
    .then(()=>{ 
    }).catch( error => { } )
  
  }

// MESSAGES
export const getMessages = (organizationId) => async (dispatch) => {
   const storageQuery = query(collection(db, "appData/qm/messages"), 
  where("organizationId", "==", organizationId))
  onSnapshot(storageQuery, async  (querySnapshot) => { 
    var messages  = [] 
      querySnapshot.forEach((doc) => {
        let data = doc.data()
        data.id = doc.id
        messages.push(data)
      }); 
      
      dispatch({
        type: 'ON_MESSAGES__LOADED',
        payload:messages
      });
    }, (error) => { }); 
};
export const createMessage = (payload) => async (dispatch, getState) => {
  var {  auth, organization  } = getState()
  const organizationId = organization?.organization?.id
  let createdBy = auth?.userData?.userId
  payload = { ...payload, organizationId , createdBy, created} 
  console.log("payload", payload);
  try{
    await addDoc(collection(db, "appData/qm/messages"),   { ...payload } )
    .then((res) => {  return  })
  .catch((error) => {   console.log("error", error);  }); 
  } catch (err) {  console.log("err", err);  }
        
};

export const updateMessage = (payload) => async (dispatch, getState) => {
  var {  auth  } = getState()
  let createdBy = auth?.userData?.userId
  payload.updatedBy = createdBy
  payload.updated = updated
  console.log("payload", payload);
   dispatch({ type: 'ON_QM_SUBMITING' });
  const taskDocRef = doc(db,  "appData/qm/messages", payload?.id)
      try {
      await updateDoc(taskDocRef, { payload, updated })
      onSnapshot(doc(db,  "appData/qm/messages", payload?.id), async (doc) => {
          return doc
  });
    } catch (err) {   return err }
};


export const deleteMessage = (id) => async (dispatch, getState) => { 
  await deleteDoc(doc(db, "appData/qm/messages", id))
.then(()=>{ 
}).catch( error => { } )

}


// Files
export const getFiles = (organizationId) => async (dispatch) => {
  const storageQuery = query(collection(db, "appData/qm/files"), 
 where("organizationId", "==", organizationId))
 onSnapshot(storageQuery, async  (querySnapshot) => { 
   var messages  = [] 
     querySnapshot.forEach((doc) => {
       let data = doc.data()
       data.id = doc.id
       messages.push(data)
     }); 
     
     dispatch({
       type: 'ON_SCHEDULES__LOADED',
       payload:messages
     });
   }, (error) => { }); 
};
export const createFile = (payload) => async (dispatch, getState) => {
 var {  auth, organization  } = getState()
 const organizationId = organization?.organization?.id
 let createdBy = auth?.userData?.userId
 payload = { ...payload, organizationId , createdBy, created} 
 console.log("payload", payload);
 try{
   await addDoc(collection(db, "appData/qm/files"),   { ...payload } )
   .then((res) => {  return  })
 .catch((error) => {   console.log("error", error);  }); 
 } catch (err) {  console.log("err", err);  }
       
};

export const updateFile = (payload) => async (dispatch, getState) => {
 var {  auth  } = getState()
 let createdBy = auth?.userData?.userId
 payload.updatedBy = createdBy
 payload.updated = updated
 console.log("payload", payload);
  dispatch({ type: 'ON_QM_SUBMITING' });
 const taskDocRef = doc(db,  "appData/qm/files", payload?.id)
     try {
     await updateDoc(taskDocRef, { payload, updated })
     onSnapshot(doc(db,  "appData/qm/files", payload?.id), async (doc) => {
         return doc
 });
   } catch (err) {   return err }
};


export const deleteFile = (id) => async (dispatch, getState) => { 
 await deleteDoc(doc(db, "appData/qm/files", id))
.then(()=>{ 
}).catch( error => { } )

}

 