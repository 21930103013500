import {
  ON_FORMS_START,
  ON_FORMS_SUCCESS,
  ON_FORMS_UPDATE,
  ON_FORMS_FAIL,
 
  SET_MESSAGE
} from "../actions/types";
const { v1: uuidv1 } = require('uuid')
 
var initialState = {
  forms:[],
  form: {
    name:'',
    description:'',
    fields:[],
    template:false,
  },
  trigger: {
    uid:uuidv1(),
    name:'New Trigger',
    type:'email',
    values:[],
    condition:'receive',
    active:true,
   },
   
  userInput:{
    name:'',
    type:'',
    page:1,
    options:[],
    conditions:{required:false}
  },
  dataCollected:[],
  dataCollection:[]
}


export default function (state = initialState, action) {
  const { type  } = action;
  
   switch (type) { 
    case 'ON_FORM_START_UPDATE': 
        return {
          ...state,
          form: action.payload
        };
 
    case 'ON_FORM_CLEAR':
      return {
        ...state,
        form: {
        name:'',
        description:'',
        fields:[],
        template:false,
      },
      userInput:{
        name:'',
        type:'',
        page:1,
        options:[],
        conditions:{required:false}
      }
    };
  
    case 'ON_FORM_KEY_UPDATE':
      let { key, payload } = action.payload  
        switch (key) {
        case 'formName':
          let form = state.form
          form['name'] = payload && payload 
          form['uid'] = uuidv1()

          return {
            ...state,
            form: form
          };
    
        case "addItem": {
          let inputOptions = (
            state.userInput.type ==='select' || 
            state.userInput.type ==='radiobutton' || 
            state.userInput.type ==='checkbox'
          )
          if(state.userInput.name==='' || state.userInput.type==='' ){ 
                return {
              ...state,
              userInput:  { ...state.userInput, fieldError:true}
            };
          }
          else if( Array.isArray(inputOptions) && !inputOptions.length){
            return {
              ...state,
              userInput:   { ...state.userInput,  fieldError:true}
            };
          } 
          else { 

          let { form  } = state
          let cleanedInput = { ...state.userInput }
          delete cleanedInput['fieldError']
          if(cleanedInput.type==='payment'){
            if(!cleanedInput.currency) cleanedInput['currency'] = 'NAD'
          }

          if(!state.userInput.reccuring){
              cleanedInput['reccuring'] = false
          }

          if(!cleanedInput.type==='verification'){
            delete cleanedInput['verification']
          }
          
            form['fields'] = [ ...form.fields, cleanedInput ]

          return {
            ...state,
            form:  form,
            userInput:{ 
              name:"", type:'', uid:'',
                conditions:{  }, fieldError:false }
          };
        }
      }
  
      case "fieldName": 
            return {
          ...state,
          userInput: Object.assign(state.userInput, { 'name':payload})
        };

        case "amount": 
            return {
          ...state,
          userInput: Object.assign(state.userInput, { 'amount':payload })
        };

      case "currency": 
            return {
          ...state,
          userInput: Object.assign(state.userInput, { 'currency':payload})
        };

      case "reccuring": 
        return {
          ...state,
          userInput: Object.assign(state.userInput, { 'reccuring':!payload})
        };

      case "page": 
      let allowedPageNumber = payload && payload > 0 ? payload : 1
            return {
        ...state,
        userInput: Object.assign(state.userInput, { 'page':allowedPageNumber})
      };

      case "description": 
        return {
          ...state,
          form: { ...state.form, 'description': payload }   
        };

      case "fieldType":
        var updatedFieldAttr 
        if(key==='payment'){
          updatedFieldAttr = Object.assign(state.userInput, { 'type':payload, 'reccuring': false , 'uid':uuidv1()} )
          delete state.userInput.options
        }
          
      else{
        let update = { ...state.userInput }
        delete update['reccuring']
        delete state.userInput.options
        updatedFieldAttr = Object.assign( update, { 'type':payload, 'uid':uuidv1()} )
      }
      return {
        ...state,
          userInput: updatedFieldAttr
      };
          
      case "verification":              
        return {
          ...state,
          userInput: { ...state.userInput,  'verification':payload }
        }; 

      case "options":
        // Remove trailing spaces and empty arrays
          let cleanedOptions = payload.split(",").filter(entry => /\S/.test(entry)).map(el => { return el.trim() })
          
        return {
          ...state,
          userInput: { ...state.userInput, 'options':cleanedOptions}
        }; 

      case "required": 
          let userInP = state.userInput
          userInP['conditions']['required'] = payload
          return {
          ...state,
          userInput: userInP
        }; 
            
      case 'active':
        let status = payload === 'true' ? true : false
        let newForm =  { ...state.form, active:status }
          return {
          ...state,
          form: newForm
        }; 

      case "removeItem": { 
          let filterd = state.form.fields && state.form.fields.filter( el => {
            return el.uid !== payload
          })
          let { form  } = state
          form['fields'] = filterd

          return {
            ...state,
            form: form
          };
        }
      
      case "resetForm": { 
        let emptyForm = state.form.fields = []
          return {
          ...state,
          form: emptyForm
        }; 
      }
  
        default:
          break;
      }
      
      break;

      case 'ON_TRIGGER_KEY_UPDATE':
        let  triggerkey  = action.payload.key
        let  triggerpayload  = action.payload.payload
           switch (triggerkey) {
            case 'name': 
                  return {
              ...state,
              trigger:  { ...state.trigger, 'name':triggerpayload} 
            };
            case "type": 
                   return {
                ...state,
                trigger: Object.assign(state.trigger, { 'type':triggerpayload})
              };

            case "values": 
            let cleanedOptions = triggerpayload.split(",").filter(entry => /\S/.test(entry)).map(el => { return el.trim() })
            
          return {
            ...state,
            trigger: { ...state.trigger, 'values':cleanedOptions}
          }; 
      
            case 'active':
              let status = triggerpayload === 'true' ? true : false
              let copyTrigger =  { ...state.trigger, active:status }
              return {
                ...state,
                trigger: copyTrigger
              }; 
    
          default:
            break;
        }
        break;


       case 'ON_DATA_COLLECTED_SUCCESS':
        return {
          ...state,
          dataCollected: action.payload 
        };
 
 
    default:
      return state;
  }
  
}
