import { combineReducers } from "redux";
import auth from "./auth";
 import organization from "./organization";
 import appReducer from "./appReducer";
 import utilities from "./utilities";
 import queueManagement from "./queueManagement";
  



import edata from "./e-data";
import digitalSignage from "./digitalSignage";


export default combineReducers({
  auth,
  appReducer,
  organization,
  utilities,
  edata,
  queueManagement,
  digitalSignage
 });
