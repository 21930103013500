// QM TYPES

// Forms 
export const ON_QM_SUBMITED                 = "ON_QM_SUBMITED"; 


// Tickets
export const ON_TICKETS_LOADED              = "ON_TICKETS_LOADED"; 
export const ON_TICKET_KEY_UPDATED              = "ON_TICKET_KEY_UPDATED";  
export const ON_TICKET_QEUEUD              = "ON_TICKET_QEUEUD"; 

// Services
export const ON_SERVICES_LOADED             = "ON_SERVICES_LOADED";
export const ON_SERVICE_KEY_UPDATED         = "ON_SERVICE_KEY_UPDATED"; 
export const ON_SERVICE_UPDATE              = "ON_SERVICE_UPDATE"; 
export const ON_KIOSK_SERVICE_CONFIG        = "ON_KIOSK_SERVICE_CONFIG"; 

// Serving points
export const ON_SERVING_POINT__LOADED       = "ON_SERVING_POINT__LOADED";
export const ON_SERVING_POINT_KEY_UPDATED   = "ON_SERVING_POINT_KEY_UPDATED";
export const ON_SERVING_POINT_UPDATE        = "ON_SERVING_POINT_UPDATE";   

// Masseges
export const ON_MESSAGE_KEY_UPDATED         = "ON_MESSAGE_KEY_UPDATED"; 
export const ON_MESSAGES__LOADED            = "ON_MESSAGES__LOADED";
export const ON_MESSAGE_UPDATED            = "ON_MESSAGE_UPDATED";

// Masseges
export const ON_SCHEDULE_KEY_UPDATED         = "ON_SCHEDULE_KEY_UPDATED"; 
export const ON_SCHEDULES__LOADED            = "ON_SCHEDULES__LOADED";
export const ON_SCHEDULE_UPDATED            = "ON_SCHEDULE_UPDATED";

// Qm analytics
export const ON_QM_ANALYTICS_LOADED         = "ON_QM_ANALYTICS_LOADED";

// Service analytics
export const ON_SERVICE_ANALYTICS_LOADED    = "ON_SERVICE_ANALYTICS_LOADED";

// Branch analytics
export const ON_BRANCH_ANALYTICS_LOADED     = "ON_BRANCH_ANALYTICS_LOADED";

 