import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux"; 
import { login } from "../../store/actions/auth";

const Login = (props) => {

  const form = useRef(); 
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();

  const onEmailChange = (e) => setUsername(e.target.value);
  const onPasswordChange = (e) => setPassword(e.target.value);
  

  const handleLogin = (e) => {

    e.preventDefault();
    setLoading(true); 
    dispatch(login(username, password))
    .then((res) => { 
      props.history.push("/apps")
        setLoading(false);
        })
      .catch((e) => {
        setLoading(false);
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 6000);
      });  
  };

  const LoginError = () => {
    return(
      <div className="alert alert-danger d-flex" role="alert">
        <span className="badge badge-center rounded-pill bg-danger border-label-danger p-3 me-2"><i className="bx bx-lock-alt fs-6"></i></span>
        <div className="d-flex flex-column ps-1">
          <h6 className="alert-heading d-flex align-items-center fw-bold mb-1">Invalid Otp Token!!</h6> 
        </div>
      </div>
    )
  }
 
  return (
    <div className="authentication-wrapper ">
      <div className="authentication-inner row m-0"> 
 
         <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg p-sm-5 p-4">
          <div className="w-px-400 mx-auto"> 
                <img src={require('../../assets/img/loop.png')} alt={''} style={{width:400}}/> 
                <div className="divider">
                  <div className="divider-text">Welcome <span role="img" aria-label={''}>👋</span></div>
                 {/* <h4 className="app-brand-text demo text-body fw-bolder">Business suite</h4> */}
                </div> 
                {
                showError ? 
                <LoginError/> : null 
                }
                {/* <p className="mb-3 ">Sign in</p>  */}

            <form id="formAuthentication" className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleLogin} ref={form} >
              <div className="mb-3 fv-plugins-icon-container">
                <label htmlFor="email" className="form-label">Mobile number</label>
                <input 
                  type="text" 
                  className="form-control typeahead tt-hint" 
                  id="email" 
                  name="email-username" 
                  placeholder="Enter your email or username" 
                  value={username}
                  disabled={loading}
                  onChange={onEmailChange}
                  autoFocus=""/>
              <div className="fv-plugins-message-container invalid-feedback"></div></div>
              <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                <div className="d-flex justify-content-between">
                  <label className="form-label" htmlFor="password">Password</label>
                
                </div>
                <div className="input-group input-group-merge has-validation">
                  <input 
                    type="password" 
                    id="password" 
                    className="form-control typeahead tt-hint" 
                    name="password" 
                    placeholder="············" 
                    aria-describedby="password"
                    value={password}
                    disabled={loading}
                    autoComplete={"current-password"}
                    onChange={onPasswordChange}
                  />
                  <span className="input-group-text cursor-pointer"><i className="bx bx-show"></i></span>
                </div><div className="fv-plugins-message-container invalid-feedback"></div>
              </div> 
              <div className="mb-3"> 
                <button className="btn btn-primary  w-100" type="submit" disabled={loading} style={{ display:'flex',alignItems:"center", justifyContent:"space-between"}}>
                  <span>Send token</span>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                  )}
                </button>
 
                </div> 
            </form>

          
 
          </div>
        </div>
       </div>
    </div> 
  )
  
 
};

export default Login;
