import React  from "react";
import { useSelector, useDispatch } from "react-redux";
 import Constants from "../../../../utils/Constants";
 import moment from 'moment' 
 import { Link } from "react-router-dom";

const format = "MMMM DD YYYY, hh:mm" 
 
 const QueueTickets = () => {

   const dispatch = useDispatch()
   var {  devices, branches }  =  useSelector((state) => state.utilities);
   var {    users }  =  useSelector((state) => state.organization);
    var  {  tickets, services }  =  useSelector((state) => state.queueManagement);
       React.useEffect(()=>{ 
        return () => {
         }
      }, [devices,  dispatch])
      
   const onFeedbackStart = (ticket) => {
    dispatch({type:'ON_TICKET_QEUEUD', payload:ticket})
   }
    return (
       
    <div className=" "> 
      <div className="row"> 
        {
          tickets && tickets !==null ?
          tickets?.map((ticket)=>{

             
             var branch = branches && branches?.find((branch)=> { return  branch.id=== ticket?.branchId }) 
              let  status = Constants.STATUS.find((s) => parseInt(s.id)===ticket.status)
             let active = status ?  <span class={`badge bg-label-${status.type}`}>{status.name}</span>  :<span class={`badge bg-label-secondary`}>INACTIVE</span>
  
             var branchName = ''
             if (ticket && ticket.branchId !== null) {
               const branch = branches !== null && branches?.find((branch)=>branch.id === ticket.branchId)
                 branchName = branch?.name
             } else {
                branchName = 'No branch'
             }
             // service
             var serviceName = ''
             var serviceLetter = ''
             if (ticket && ticket.serviceId !== null) {
               var service = services !== null && services?.find((s)=>s.id === ticket.serviceId)
               serviceName = service?.name
               serviceLetter = service?.letter
             } else {
                serviceName = 'No service name'
             }

           let created = `${moment(new Date(ticket.created * 1000).toJSON()).format(format) || 'No date'}`
           let consultant =  users && users?.find((u)=>  u.userId===ticket.agentId )
           var fullname = ""
           if(consultant){
             const { firstname, lastname }  = consultant
             fullname = ( firstname   +  ' ' + lastname[0])
           }
             return <div key={ticket?.id + ticket.created} className="col-lg-3 col-md-6 col-sm-6 mb-2">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
              <h3 className="card-title mb-1 me-2">{ serviceName   } - { serviceLetter + "-" + ticket.ticketNumber}</h3> 
             
              </div>
              <div className="card-body ">  
                <h6 className="text-muted">{ active } {branch?.name} </h6>
                 <div className="d-flex justify-content-between">  
                    <div className="d-flex flex-column">
                      <small className="text-muted">Time :  { ticket?.servintTime || '0 minutes'} - {created}</small>
                     </div> 
                  </div>  
                   <Link to="/queue/feedback" onClick={()=>onFeedbackStart(ticket)}  className="btn btn-sm btn-secondary mt-2"tabIndex="0"  
                      >
                      <span class="tf-icons bx bx-chat"></span>&nbsp; Feedback
                  </Link> <button  className="btn btn-sm btn-secondary mt-2"tabIndex="0"  
                      >
                      <span class="tf-icons bx bx-emoji"></span>&nbsp; Follow up
                  </button>
                    <div className="d-flex flex-column align-items-center mt-2"> 
                 </div>
              </div>
            </div>
          </div> 
          }) :  <div >
          <div className="card"> 
            <div className="card-body text-center"> 
              <h3 className="card-title mb-1 me-2">No ticket yet, cliuvk queue at top right</h3>
             </div>
          </div>
        </div> 
        }
         
        </div>  
 
    </div>
   );
};



export default QueueTickets
 