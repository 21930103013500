import React from "react";
  
export const Footer = () => { 
  return (
      <footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div className="mb-2 mb-md-0">©<script>document.write(new Date().getFullYear());</script>2022 , a product of  &nbsp;
                <a href="https://loop.com.na" rel="noopener noreferrer" target="_blank" className="footer-link fw-bolder"> Loop technologies</a>
            </div>
            <div>
                <a className="footer-link me-4" rel="noopener noreferrer" href="/"  target="_blank">Terms and privacy policy</a>
                <a className="footer-link me-4" href="/">Market place</a>
                <a className="footer-link me-4" href="/" target="_blank">Documentation</a>
                <a className="footer-link me-4" href="/">Support</a>
            </div>
        </div>
    </footer>
   );
};

 