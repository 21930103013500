import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"; 
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; 

const firebaseConfig = { 
  	apiKey: "AIzaSyDtMMdm5Mp1IIq-R3S11u8qwwW3BNVq-Ww",
	authDomain: "hermes-e6582.firebaseapp.com",
	databaseURL: "https://hermes-e6582-default-rtdb.firebaseio.com",
	projectId: "hermes-e6582",
	storageBucket: "hermes-e6582.appspot.com",
	messagingSenderId: "409372579973",
	appId: "1:409372579973:web:5024ca1a3facf632dff1ec",
	measurementId: "G-CCVWV69JB7",
};
 
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app)
const rtdb = getDatabase(app);
 

export { db, auth, rtdb, storage };
