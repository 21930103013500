import React from "react";
import { useSelector } from "react-redux";
import Stats from './queue/Stats'
import QueueTickets from './queue/QueueTickets'


const Tickets = () => { 
     var  {  tickets }  =  useSelector((state) => state.queueManagement);
 
   return (
       
      <> 
           <Stats/>
           <QueueTickets tickets={tickets}/>
     </>
   );
};

export default Tickets;
