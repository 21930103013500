import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";


export const AsideContent = () => { 

  var  {userData}  = useSelector((state) => state.auth);
  var  currUtilities  =  useSelector((state) => state);
  var  { apps, organization }  =  useSelector((state) => state.organization);
  const [ myUtilities, setMyUtilities ] = React.useState([])
  const [showResults, setShowResults] = React.useState(false) 
  const [showApps, setShowApps] = React.useState(false)
  const [showSettings, setShowSettings] = React.useState(false)
  
   const dispatch = useDispatch();
   const onClick = () => {
    setShowResults(!showResults)
    if(showResults)  toggleMainMenu() 
  }
  const onShowApps = () =>{ 
    setShowApps(!showApps)
    if(showApps)  toggleMainMenu() 
  }
  const onSettings = () =>{ 
    setShowSettings(!showSettings)
    if(showSettings)  toggleMainMenu() 
   }

   var toggleMainMenu = () => { dispatch({type:'TOGGLE_MAIN_MENU'})}
 
   React.useState(()=>{ 
    if ( currUtilities && currUtilities?.organization) {
        
        var { 
          users, devices, blocks, branches, departments,
          storage 
        } = currUtilities && currUtilities?.organization

        var utilObj = [
            { key:'users', values:users, icon:"people-outline" }, 
            { key:'devices', values:devices, icon:"file-tray-full-outline" }, 
            { key:'Categories', values:blocks, icon:"grid-outline" }, 
            { key:'branches', values:branches, icon:"git-branch-outline" }, 
            { key:'departments', values:departments, icon:"list-outline" }, 
            { key:'storage', values:storage, icon:"cloud-outline" }, 
           ] 
          
          setMyUtilities(utilObj)
        }  
      },[ currUtilities ])
   
      if(!myUtilities){
        return <div data-i18n=" ">laoding...</div>
      }

     

       
      const Ulitities = () => (
        <>
          { myUtilities ? myUtilities.map((utilitiy) => {
              return (
                <li className="menu-item" key={utilitiy.key}>
                  <NavLink to={"/utilities/" + utilitiy.key} className="menu-link" onClick={onClick}>
                    <div data-i18n="Without navbar" style={{textTransform:'capitalize'}}>{utilitiy.key}</div>
                  </NavLink>
                </li>
              )
            }) : null }
        </>
      )

      // Only return apps that are products
      const permissions = userData && userData?.permissions
      if (apps !== null && permissions !== null && userData !== null) {
        var permittedApps = []
        apps && apps.map(app => {
          return permissions && permissions.forEach(data => {
            if (data === app.id) {
              permittedApps.push(app)
            }
          })
        })
      }

 
  return (
     <>
      

          <div className="menu-inner-shadow"></div>

          <ul className="menu-inner py-1 ps ps--active-y">
            

 

          
       
             <li className="menu-header small text-uppercase">
              <span className="menu-header-text">Explore</span>
            </li>

            <li  className={`menu-item`}>
            <NavLink to={'/'} className="menu-link" >
                <i className="menu-icon tf-icons bx bx-home"></i>
                <div data-i18n="Layouts">Home</div>
              </NavLink> 
            </li>
            <li  className={`menu-item`}>
            <NavLink to={'/dm'} className="menu-link" >
                <i className="menu-icon tf-icons bx bx-chat"></i>
                <div data-i18n="Layouts">Chats</div>
              </NavLink> 
            </li>
          
            <li  className={ `menu-item  ${showResults ? "open" : "close"}` }>
            
            
            <li className={`menu-item`}>
            <NavLink to={'/shopping'} className="menu-link" >
                <i className="menu-icon tf-icons bx bx-cart"></i>
                <div data-i18n="Layouts">Shopping</div>
              </NavLink> 
            </li>
            <li  className={`menu-item`}>
            <NavLink to={'/order'} className="menu-link" >
                <i className="menu-icon tf-icons bx bx-restaurant"></i>
                <div data-i18n="Layouts">Restaurants</div>
              </NavLink> 
            </li>

            
              
            </li>
            <li  className={ `menu-item  ${showSettings ? "open" : "close"}` }>
            <div className="menu-link menu-toggle" aria-expanded={showSettings ? "true" : "false"} onClick={onSettings}>
                <i className="menu-icon tf-icons bx bx-dots-vertical-rounded"></i>
                <div data-i18n="Layouts">More</div>
              </div>
              { 

                showSettings && userData &&
                <ul className="menu-sub">
                    <li className="menu-item">
                      <NavLink to={"/settings/"+ organization?.id} onClick={onSettings} className="menu-link">
                        <div data-i18n="Container">Book a Cab</div>
                      </NavLink>
                    </li>   
                  </ul> 
              }
            </li>

     
           
      
       
          </ul>
     </>
   );
};

 