import {
    ON_DS_SUBMITED,

  

    ON_SCHEDULES__LOADED,
    ON_SCHEDULE_KEY_UPDATED,
    ON_DS_SCHEDULE_UPDATED,

    ON_LIST_KEY_UPDATED,
    ON_NEWS_FEEDS_LOADED,
    ON_NEWS_FEEDS_UPDATED,

    ON_PRESANTATIONS_LOADLED,
    ON_SCHEDULE_TYPES__LOADLED,

    ON_THEME_KEY_UPDATE,
    ON_SETTINGS_UPDATED,
 
    ON_QM_ANALYTICS_LOADED,
    
} from "../types/digital-signage/types"

const defaultTheme = {
  mainBgColor:"#2f80ed",
  sideBgColor:"#FFFFFF",
  sideTxtColor:"#3A416F",
  newsSectionBgColor:"#FFFFFF",
  newsSectionTitleColor:"#3A416F",
  newsSectionTextColor:"#777",
  scrollSectionTitleColor:"#333",
  scrollSectionTextColor:"#FFFFFF"
}
var initialState = {
  productId : "h5tCHPaaoGvc7b84BE5u",

  loadingDs : false,

   pageId : "",
    newsFeeds : null,
   newsFeedList : { id:null, title:"", rss:false, listItems:[], userInput:"", branches:[], listStatus:'1' },
    defaultTheme : { },
    theme : {
      mainBgColor:"#2f80ed",
      sideBgColor:"#FFFFFF",
      sideTxtColor:"#3A416F",
      newsSectionBgColor:"#FFFFFF",
      newsSectionTitleColor:"#3A416F",
      newsSectionTextColor:"#777",
      scrollSectionTitleColor:"#333",
      scrollSectionTextColor:"#FFFFFF"
    },
    listCrud: { },
 
    schedules            :[],
  
    teams : null,
    newsFeed :[],
    
    // CRUD SERVICES 
    team             : { id:null, title:"", firstname:"", lastname:"", occupation:"", mediaObject:[], mediaId:"", branches:[], cardStatus:'1' },
    mediaStorage:null,
    mediaUpload : { id:null, title:"", description:"", mediaObject:[], mediaUrl:"" },
    uploadProgress: 0 ,

    schedule:{
      id:null, title:'', mediaId:null, active:true, created:"", sectionId:"",branches:[],
      conditions:[], scheduleTypeId:"", scheduleDate:null, duration:"6000", weatherTitle:"",
      volume:0, scheduleType:"", fullScreen:false, mediaType:"", playForever:false, scheduleStatus:'1',
    
    },
    settingsConfig:{id:""},
    scheduleCreateConfig :{
      selected:null,
      mediaVisible:false,
      mediaSelected:false
    },
    presentations:null,
    scheduleTypes : null
}
  
export default (state = initialState, action) => {
const { type, payload } = action
 switch (type) {
    

    case "ON_DS_SUBMITED":
        return {
            ...state,
             
            mediaUpload : { id:null, title:"", description:"", mediaObject:[], mediaUrl:"" },
            uploadProgress: 0 ,
        
            schedule:{
              id:null, title:'', mediaId:null, active:true, created:"", sectionId:"",branches:[],
              conditions:[], scheduleTypeId:"", scheduleDate:null, duration:"6000", weatherTitle:"",
              volume:0, scheduleType:"", fullScreen:false, mediaType:"", playForever:false, scheduleStatus:'1'
            },
            scheduleCreateConfig :{
              selected:null,
              mediaVisible:false,
              mediaSelected:false
            },
             newsFeedList : { id:null, title:"", rss:false, listItems:[], userInput:"",branches:[], listStatus:'1' },
          listCrud: { },
        
        };

        case ON_THEME_KEY_UPDATE:  
        let themeCopy = state?.theme 
        themeCopy[payload.key] = payload.val 
        return { ...state, theme: themeCopy };
        

        case ON_LIST_KEY_UPDATED: //
        var listCopy = state.newsFeedList
            switch(payload.key){ 
        
              case "title":  
                listCopy["title"] = payload?.val 
                return {
                  ...state, newsFeedList:listCopy 
                }  
              case "addItem":
              
                  const {   userInput } = state.newsFeedList
                  const  listItems  = state.newsFeedList?.listItems
                  listCopy["listItems"] =[...listItems, userInput ]
                  listCopy["userInput"] =  "" 
                    
                 return {
                  ...state,
                  newsFeedList:listCopy
                } 
                case "branches":
              
                   const  copy  = state.newsFeedList?.branches
                  listCopy["listItems"] =[...copy, payload?.val  ]
                     
                 return {
                  ...state,
                  newsFeedList:listCopy
                } 


            case "userInput":
              listCopy["userInput"] =  payload.val
               return {
                ...state, newsFeedList:listCopy
              }

              case "listStatus":
              listCopy["listStatus"] =  payload.val
               return {
                ...state, newsFeedList:listCopy
              } 
            case "rss":
              listCopy["rss"] = !listCopy["rss"] 
                return {
                  ...state, newsFeedList:{...listCopy, listItems:[]} 
                }
            //    {  
            case "removeItem": 
              var list = listCopy.listItems 
               list.splice(payload.val, 1)
               return {
                ...state, newsFeedList:{ ...state.newsFeedList, listItems:list} 
              } 
              default: {}
          } 

    
    case ON_SCHEDULE_KEY_UPDATED: //  
       var crudCopy = state.schedule
        switch(payload.key){
        
        case "title": 
            crudCopy["title"] = payload.val
            return { ...state, schedule:crudCopy};
            
          case "mediaId":   
            crudCopy["mediaId"] = payload?.val
            let sC = state.scheduleCreateConfig
            sC['selected'] = payload.val
            sC['mediaSelected'] = true 
            return {
              ...state, schedule:crudCopy, scheduleCreateConfig:sC
            }
      
          case "sectionId": 
            let sIT = state.scheduleCreateConfig
              crudCopy["sectionId"] =payload.val
              crudCopy["mediaType"] =''
              crudCopy["scheduleTypeId"] = ''
              // crudCopy["scheduleType"] = ''
              sIT['selected'] = null
              sIT['mediaSelected'] = false  
              crudCopy["volume"] = 0
              crudCopy["conditions"] = []
              crudCopy["fullScreen"] = false 
              return { ...state, schedule:crudCopy, scheduleCreateConfig:sIT}; 
      
          case "scheduleTypeId":  
              let scT = state.scheduleCreateConfig
              crudCopy["scheduleType"] =payload.val
              crudCopy["scheduleTypeId"] =payload.val
              crudCopy["mediaType"] =''
              scT['selected'] = null
              scT['mediaSelected'] = false  
              crudCopy["volume"] = 0
              crudCopy["conditions"] = []
              crudCopy["fullScreen"] = false 
            return { ...state, schedule:crudCopy, scheduleCreateConfig:scT}; 
      
          case "mediaType":   
             let mT = state.scheduleCreateConfig
              crudCopy["scheduleType"] =payload.val
              crudCopy["mediaType"] =payload.val
              mT['selected'] = null
              mT['mediaSelected'] = false  
              crudCopy["volume"] = 0
              crudCopy["conditions"] = []
              crudCopy["fullScreen"] = false
              crudCopy["duration"] = '6000'
              crudCopy["weatherTitle"] =""
            return { ...state, schedule:crudCopy, scheduleCreateConfig:mT}; 
            
          case "removeMedia":
              let rM = state.scheduleCreateConfig
              crudCopy["mediaId"] = ""
              rM['selected'] = null
              rM['mediaSelected'] = false  
              crudCopy["volume"] = 0
              crudCopy["fullScreen"] = false
              crudCopy["duration"] = '6000'
              crudCopy["weatherTitle"] =""
            return { ...state, schedule:crudCopy, scheduleCreateConfig:rM}; 

          case "weatherTitle": 
            crudCopy["weatherTitle"] = payload.val
            return { ...state, schedule:crudCopy}; 

          case "scheduleDate":
            crudCopy["scheduleDate"] = payload.val
            return { ...state, schedule:crudCopy}; 
          
          case "duration": 
              crudCopy["duration"] = payload.val
              return { ...state, schedule:crudCopy};

          case "volume":
            crudCopy["volume"] = payload.val
            return { ...state, schedule:crudCopy};

          case "fullScreen": 
            crudCopy["fullScreen"] = payload.val
            return { ...state, schedule:crudCopy}; 
            

          case "conditions":
            crudCopy["conditions"] = payload.val
            return { ...state, schedule:crudCopy};

          case "branches": 
            crudCopy["branches"].push(payload.val)
            return { ...state, schedule:crudCopy};
          
          case "scheduleStatus": 
            crudCopy["scheduleStatus"] = (payload.val)
            return { ...state, schedule:crudCopy};
            
          
          case "active": //scheduleStatus
              crudCopy["active"] = true
              crudCopy["scheduleStatus"] = !crudCopy["scheduleStatus"]
          return { ...state, schedule:crudCopy};
          case "conditions":
            crudCopy["active"] = payload.val
            return { ...state, schedule:!crudCopy["active"]}; 
          
            default: {}
        }
        
        

    case ON_DS_SCHEDULE_UPDATED: // settingsConfig 
     let sC = state.scheduleCreateConfig
     
      return { ...state, newsFeed:sC, schedule:state.schedules.find((schedule)=>  schedule.id === action.payload)};

      case ON_SETTINGS_UPDATED:  
        return { ...state, theme:action?.payload?.theme || {
        mainBgColor:"#2f80ed",
        sideBgColor:"#FFFFFF",
        sideTxtColor:"#3A416F",
        newsSectionBgColor:"#FFFFFF",
        newsSectionTitleColor:"#3A416F",
        newsSectionTextColor:"#777",
        scrollSectionTitleColor:"#333",
        scrollSectionTextColor:"#FFFFFF"
       }, settingsConfig: {id:action?.payload?.id }};
 
     case ON_NEWS_FEEDS_UPDATED: //   
        return { ...state, newsFeedList:state.NewsFeeds.find((feed)=>  feed.id === action.payload)};
 
 
    case ON_SCHEDULES__LOADED               : return { ...state, schedules: payload } //

    case ON_NEWS_FEEDS_LOADED : 
    return { ...state, NewsFeeds: payload } //

    case ON_PRESANTATIONS_LOADLED          : return { ...state, presentations: payload }

    case ON_SCHEDULE_TYPES__LOADLED        : return { ...state, scheduleTypes: payload }
 

 
      
    default: return state

    }
}
  