// QM TYPES

// Forms 
export const ON_DS_SUBMITED                 = "ON_DS_SUBMITED"; 

 
// Forms 
export const ON_THEME_KEY_UPDATE                 = "ON_THEME_KEY_UPDATE"; 

 

// lists
export const ON_NEWS_FEEDS_LOADED             = "ON_NEWS_FEEDS_LOADED";
export const ON_LIST_KEY_UPDATED         = "ON_LIST_KEY_UPDATED"; 
export const ON_NEWS_FEEDS_UPDATED              = "ON_NEWS_FEEDS_UPDATED";  
export const ON_KIOSK_SERVICE_CONFIG        = "ON_KIOSK_SERVICE_CONFIG"; 

// teams
export const ON_TEAMS__LOADED       = "ON_TEAMS__LOADED";
export const ON_TEAM_KEY_UPDATED   = "ON_TEAM_KEY_UPDATED";
export const ON_TEAM_UPDATE        = "ON_TEAM_UPDATE";   
 
// SCHEDULES
export const ON_SCHEDULE_KEY_UPDATED         = "ON_SCHEDULE_KEY_UPDATED"; 
export const ON_SCHEDULES__LOADED            = "ON_SCHEDULES__LOADED";
export const ON_DS_SCHEDULE_UPDATED            = "ON_DS_SCHEDULE_UPDATED";

// SCHEDULES
export const ON_SETTINGS_UPDATED         = "ON_SETTINGS_UPDATED"; 
 



// Qm analytics
export const ON_DS_ANALYTICS_LOADED         = "ON_DS_ANALYTICS_LOADED";


export const ON_PRESANTATIONS_LOADLED = "ON_PRESANTATIONS_LOADLED" 
export const ON_SCHEDULE_TYPES__LOADLED = "ON_SCHEDULE_TYPES__LOADLED" 

 