
import { getDocs, collection, query, where, doc, addDoc, updateDoc, onSnapshot} from "firebase/firestore";
import { db } from "../../utils/firebase";
import authService from "./authService";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import bcrypt from 'bcryptjs'

const auth = getAuth();
var dayjs = require('dayjs')

const getUserService = async ( userId ) => {
  const q = query(collection(db, "users"), where("userId", "==", userId))
  const querySnapshot = await getDocs(q);
  var user
    querySnapshot.forEach((doc) => {
     var data = {}
      data = doc.data()
      data.id = doc.id 
        user = data
    }); 
    return Promise.resolve(user); 
};


const createUserService = async ( payload ) => {
  var { 
    branchId,  email, firstname, lastname, departmentId,
    organizationId,  password , permissions, profileUrl, 
    role
   } = payload
   var admin = parseInt(role) === 1 ? true : false 
   var hashedPassword = await bcrypt.hashSync(password, bcrypt.genSaltSync())
   
   var now = dayjs().format()
   var updated = dayjs(now).unix()
   var created = dayjs(now).unix()

   const auth = getAuth();
   await createUserWithEmailAndPassword( auth, email, password)
   .then( async (userCredential) => {
    // Signed up 
    const user = userCredential?.user; 
      try{
          await addDoc(collection(db, "users"), {
          admin, userId:user.uid, branchId, created, email, 
          firstname, lastname, organizationId, departmentId,
          password:hashedPassword, permissions, profileUrl, 
          role, updated, active:true
        });
   
    } catch (err) { }
   }) 
  .catch((error) => { });  
};


const updateUserService = async ( payload ) => {
   let { 
    admin, userId, branchId, created, email, oldEmail,
    firstname, lastname, organizationId, 
    password, oldPassword, permissions, profileUrl, 
    role, uuid
   } = payload
   console.log("Payload====", payload);
  //  var newPassword = '' // hash created previously created upon sign up
  //  if(password !== oldPassword ){
  //     //  Update new password
  //     newPassword = bcrypt.hashSync(password, bcrypt.genSaltSync())
  //     authService.updateUserPassword(auth.currentUser, password) 
  //  }
  //  else {
  //   //  Do not update
  //   newPassword = oldPassword
  //  }
  //  var newEmail = '' // hash created previously created upon sign up
  //  if(oldEmail===email){
  //   newEmail = oldEmail
  //  }
  //  else {
  //   newEmail = email
  //    authService.updateUserEmail(auth.currentUser, email)
  //  }

   var now = dayjs().format()
   var updated = dayjs(now).unix() 
  delete payload['oldPassword']
  delete payload['oldEmail']

  const taskDocRef = doc(db, 'users', payload?.id)
  var newPromise = null
  //  try{
  //   await updateDoc(taskDocRef, {
  //     admin, userId, branchId, created, email:newEmail, 
  //     firstname, lastname, organizationId,
  //     password:newPassword, permissions, profileUrl, 
  //     role, updated, uuid
  //   }) 
  //      onSnapshot(doc(db, "users", payload?.id), async (doc) => {
  //     if (doc) {
  //       let id = doc.id
  //       newPromise = { id, ...doc.data()}  
  //    }
  // });
  //  } catch (err) { } 
    // return Promise.resolve(newPromise); 
};
 
export default {
  getUserService,
  createUserService,
  updateUserService,
};

 