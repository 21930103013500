import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

export const NavContent = () => { 

  var { user : currentUser, userData } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  var toggleMainMenu = () => { dispatch({type:'TOGGLE_MAIN_MENU'})}
  var logMeOut = () => { dispatch({type:'LOGOUT'})}

  const toggleTheme = () => {
    const themeClass = document.getElementsByTagName("html")[0];
    if(themeClass.classList.contains("light-style")) {
      themeClass.classList.remove("light-style");
      themeClass.classList.add("dark-style");
    }
    else {
      themeClass.classList.remove("dark-style");
      themeClass.classList.add("light-style");
    }
  }
  
  const roles = [
    {value:'1', name: 'Super Admin'},
    {value:'2', name: 'Admin'},
    {value:'3', name: 'Branch manager'},
    {value:'4', name: 'Supervicer'},
    {value:'5', name: 'Consultant'},
  ]
  
   const role = roles && roles.find( (el) => el.value === userData?.role) 
   if(userData){ 
    var { firstname, lastname } = userData
   }


  return (
     <>
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none" style={{cursor:"pointer"}} onClick={()=>toggleMainMenu()}>
          <div className="nav-item nav-link px-0 me-xl-4" >
            <i className="bx bx-menu bx-sm"></i>
          </div>
        </div>
        <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
               <div className="navbar-nav align-items-center">
                <div className="nav-item navbar-search-wrapper mb-0">
                  <Link className="nav-item nav-link search-toggler px-0" to="">
                    <i className="bx bx-search bx-sm"></i>
                    {/* <span className="d-none d-md-inline-block text-muted">Search (Ctrl+/)</span> */}
                  </Link>
                </div>
              </div>
 
              <ul className="navbar-nav flex-row align-items-center ms-auto">
                

                <Link to={'/apps'} style={{cursor:'pointer'}} className="nav-item  me-2 me-xl-0">
                  <div className="nav-link  hide-arrow"  >
                    <i className="bx bx-grid-alt bx-sm"></i>
                  </div>
                  
                </Link>
 
                 <li style={{cursor:'pointer'}} className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                  <div className="nav-link dropdown-toggle hide-arrow"   data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    <i className="bx bx-bell bx-sm"></i>
                    <span className="badge bg-danger rounded-pill badge-notifications">5</span>
                  </div>
                  <ul className="dropdown-menu dropdown-menu-end py-0">
                    <li className="dropdown-menu-header border-bottom">
                      <div className="dropdown-header d-flex align-items-center py-3">
                        <h5 className="text-body mb-0 me-auto">Notification</h5>
                        <div   className="dropdown-notifications-all text-body" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Mark all as read" aria-label="Mark all as read"><i className="bx fs-4 bx-envelope-open"></i></div>
                      </div>
                    </li>
                    <li className="dropdown-notifications-list scrollable-container ps">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img src="../../assets/img/avatars/1.png" alt="" className="w-px-40 h-auto rounded-circle"/>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Congratulation Lettie 🎉</h6>
                              <p className="mb-0">Won the monthly best seller gold badge</p>
                              <small className="text-muted">1h ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <div  className="dropdown-notifications-read"><span className="badge badge-dot"></span></div>
                              <div   className="dropdown-notifications-archive"><span className="bx bx-x"></span></div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <span className="avatar-initial rounded-circle bg-label-danger">CF</span>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Charles Franklin</h6>
                              <p className="mb-0">Accepted your connection</p>
                              <small className="text-muted">12hr ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <div className="dropdown-notifications-read"><span className="badge badge-dot"></span></div>
                              <div  className="dropdown-notifications-archive"><span className="bx bx-x"></span></div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img src="../../assets/img/avatars/2.png" alt="" className="w-px-40 h-auto rounded-circle"/>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">New Message ✉️</h6>
                              <p className="mb-0">You have new message from Natalie</p>
                              <small className="text-muted">1h ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <div  className="dropdown-notifications-read"><span className="badge badge-dot"></span></div>
                              <div   className="dropdown-notifications-archive"><span className="bx bx-x"></span></div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <span className="avatar-initial rounded-circle bg-label-success"><i className="bx bx-cart"></i></span>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Whoo! You have new order 🛒</h6>
                              <p className="mb-0">ACME Inc. made new order $1,154</p>
                              <small className="text-muted">1 day ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <div  className="dropdown-notifications-read"><span className="badge badge-dot"></span></div>
                              <div  className="dropdown-notifications-archive"><span className="bx bx-x"></span></div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img src="../../assets/img/avatars/9.png" alt="" className="w-px-40 h-auto rounded-circle"/>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Application has been approved 🚀</h6>
                              <p className="mb-0">Your ABC project application has been approved.</p>
                              <small className="text-muted">2 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <div   className="dropdown-notifications-read"><span className="badge badge-dot"></span></div>
                              <div  className="dropdown-notifications-archive"><span className="bx bx-x"></span></div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <span className="avatar-initial rounded-circle bg-label-success"><i className="bx bx-pie-chart-alt"></i></span>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Monthly report is generated</h6>
                              <p className="mb-0">July monthly financial report is generated</p>
                              <small className="text-muted">3 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <div  className="dropdown-notifications-read"><span className="badge badge-dot"></span></div>
                              <div   className="dropdown-notifications-archive"><span className="bx bx-x"></span></div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img src="../../assets/img/avatars/5.png" alt="" className="w-px-40 h-auto rounded-circle"/>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">Send connection request</h6>
                              <p className="mb-0">Peter sent you connection request</p>
                              <small className="text-muted">4 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <div  className="dropdown-notifications-read"><span className="badge badge-dot"></span></div>
                              <div   className="dropdown-notifications-archive"><span className="bx bx-x"></span></div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <img src="../../assets/img/avatars/6.png" alt="" className="w-px-40 h-auto rounded-circle"/>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">New message from Jane</h6>
                              <p className="mb-0">Your have new message from Jane</p>
                              <small className="text-muted">5 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <div   className="dropdown-notifications-read"><span className="badge badge-dot"></span></div>
                              <div  className="dropdown-notifications-archive"><span className="bx bx-x"></span></div>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar">
                                <span className="avatar-initial rounded-circle bg-label-warning"><i className="bx bx-error"></i></span>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="mb-1">CPU is running high</h6>
                              <p className="mb-0">CPU Utilization Percent is currently at 88.63%,</p>
                              <small className="text-muted">5 days ago</small>
                            </div>
                            <div className="flex-shrink-0 dropdown-notifications-actions">
                              <div   className="dropdown-notifications-read"><span className="badge badge-dot"></span></div>
                              <div   className="dropdown-notifications-archive"><span className="bx bx-x"></span></div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    <div className="ps__rail-x" style={{left: 0, bottom: 0,}}><div className="ps__thumb-x" tabindex="0" style={{left: 0, width: 0}}></div></div><div className="ps__rail-y" style={{top: 0, right: 0}}><div className="ps__thumb-y" tabindex="0" style={{top: 0, height: 0}}></div></div></li>
                    <li className="dropdown-menu-footer border-top">
                      <div   className="dropdown-item d-flex justify-content-center p-3">
                        View all notifications
                      </div>
                    </li>
                  </ul>
                </li> 

                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <div className="nav-link dropdown-toggle hide-arrow"  data-bs-toggle="dropdown">
                    <div className="avatar avatar-online">
                      <img style={{maxWidth:100, maxHeight:100}} src={userData?.profileUrl || ''?.profileUrl || ''} alt="" className=" rounded-circle"/>
                    </div>
                  </div>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <Link to={`/profile/${ currentUser && currentUser?.uid}`} className="dropdown-item" >
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar avatar-online">
                              <img style={{maxWidth:100, maxHeight:100}} src={userData?.profileUrl || ''} alt="" className=" rounded-circle"/>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-semibold d-block">{ currentUser ? `${ firstname + ' ' +  lastname } ` : 'No name'}</span>
                            <small className="text-muted">{role?.name || 'No role'}</small>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li> 
                    <li>
                      <Link to={`/billing/${ currentUser && currentUser?.uid}`} className="dropdown-item" >
                        <span className="d-flex align-items-center align-middle">
                          <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                          <span className="flex-grow-1 align-middle">Billing</span>
                          <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                        </span>
                      </Link>
                    </li>
                   
                    <li>
                      <Link to={`/profile/${ currentUser && currentUser?.uid}`} className="dropdown-item"  >
                        <i className="bx bx-user me-2"></i>
                        <span className="align-middle">My Profile</span>
                      </Link>
                    </li> 
                    <li>
                      <Link to={`/account/${ currentUser && currentUser?.uid}`} className="dropdown-item"  >
                        <i className="bx bx-cog me-2"></i>
                        <span className="align-middle">Manage account</span>
                      </Link>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" href="docs.loop.com.na/help">
                        <i className="bx bx-support me-2"></i>
                        <span className="align-middle">Help</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="docs.loop.com.na/faq">
                        <i className="bx bx-help-circle me-2"></i>
                        <span className="align-middle">FAQ</span>
                      </a>
                    </li> 
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <div style={{cursor:'pointer'}} onClick={logMeOut} className="dropdown-item" >
                        <i className="bx bx-power-off me-2"></i>
                        <span className="align-middle">Log Out</span>
                      </div>
                    </li>
                  </ul>
                </li>
               </ul>
            </div>
     </>
   );
};

 