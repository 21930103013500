import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ON_USER_SUCCESS, 
} from "../actions/types";
 
var user = JSON.parse(localStorage.getItem("user"));

user = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };
  
  var initialState = {
    user:user,
    userData:null,
    settings:{},
  }

  // user reducer
export default function (state = initialState, action) {
  const { type, payload } = action;

   switch (type) {

    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };

    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };

    case LOGIN_SUCCESS:
    let { user } = payload &&  payload.user
    let { metadata, uid, email, phoneNumber, accessToken } =  user
    
    return {
      ...state,
      isLoggedIn: true,
      user: { metadata, uid, email, phoneNumber, accessToken }
    }

    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        userData: null
      };

    case ON_USER_SUCCESS:
       return {
        ...state,
        userData: payload,
         };
 
    default:
      return state;
  }
}
